<template>
  <va-card :title="formatted()">
    <text-list :title="$t('teams.inputs.presentationsNumberInput')">
      {{ presentations }}
    </text-list>
    <text-list :title="$t('teams.inputs.motherChurchInput')">
      {{ motherChurch }}
    </text-list>
    <text-list :title="$t('teams.inputs.preachingPointInput')">
      {{ preachingPoint }}
    </text-list>
    <text-list :title="$t('teams.inputs.preachingLeaderInput')">
      {{ leaderName }}
    </text-list>
  </va-card>
</template>

<script>
import parse from 'date-fns/parse'
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'monthly-view',
  components: {
    TextList,
  },
  props: {
    currentMonth: {
      type: String,
      required: true,
    },
    remoteData: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      presentations: null,
      motherChurch: '',
      preachingPoint: '',
      leaderName: '',
    }
  },
  watch: {
    remoteData (val) {
      this.updateWithRemote(val)
    },
  },
  created () {
    this.updateWithRemote(this.remoteData)
  },
  methods: {
    updateWithRemote (val) {
      if (!val.id) return

      this.presentations = val.presentations
      this.motherChurch = val.motherChurch
      this.preachingPoint = val.preachingPoint
      this.leaderName = val.leaderName
    },
    formatted () {
      if (!this.currentMonth) return ''
      const date = parse(this.currentMonth, 'MM/dd/yyyy', new Date())
      const formatted = this.$date.format(date, 'MMMM - yyyy')
      return this.capitalize(formatted)
    },
    capitalize (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
  },
}
</script>
